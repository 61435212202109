

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <!-- <el-row>
          <el-col :span="18">
            <el-input placeholder="请输入用户昵称" v-model="searchForm.title" clearable />
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="dataBinding()">搜索</el-button>
          </el-col>
        </el-row> -->
      </div>
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="point" label="功力值" />
        <el-table-column prop="remark" label="记录信息" />
        <el-table-column prop="createDateTime" label="创建日期" />
        <el-table-column prop="isSys" label="录入来源">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isSys ? 'primary' : 'info'" disable-transitions>
              {{scope.row.isSys?'系统':'管理员'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="pointSourceTitle" label="案例名称" />
        <el-table-column prop="pointSourceTypeName" label="来源类型" />
        <el-table-column prop="managerName" label="来源管理员昵称" />
        <el-table-column prop="managerAccount" label="来源管理员昵称" />
      </el-table>
      <div class="margin-t16 text-right">
      </div>
    </el-card>
  </div>
</template>
<script>
import { pointRecordList } from '@/service/appuser.js';

export default {
  data() {
    return {
      loading: false,
      searchOpen: false,
      totalCount: 0,
      searchForm: {
        appUserId: 0,
        page: 1,
        pageSize: 20,
        startDate: null,
        endDate: null
      },
      tableData: []
    };
  },
  created() {
    if (this.$route.query.appUserId) {
      this.searchForm.appUserId = 0;
      this.searchForm.appUserId = this.$route.query.appUserId;
      this.dataBinding();
    }
    this.dataBinding();
  },
  methods: {
    //跳转编辑案例页
    handleEditModel(row) {
      this.$router.push({ name: 'article-edit', query: { articleId: row.id } });
    },
    //加载页面数据
    dataBinding() {
      this.loading = true;
      pointRecordList(this.searchForm).then(res => {
        console.log(res);
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    }

  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>